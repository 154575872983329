<template>
  <v-container fluid>
    <div v-if="isLoading"></div>
    <div v-else>
      <base-page-title
        >Fixture Design Fulfillment: Fixture Design({{ fixturePk }}) {{ partNumber }}</base-page-title
      >
      <v-toolbar v-if="stateDescription" dense flat>
        <span>
          Fixture Design State:
          {{ fixtureStateName }}
          - {{ stateDescription }}
        </span>
        <v-spacer />
        <v-btn
          v-if="isAdmin"
          color="warning"
          class="mr-1"
          @click="onGenerate"
          data-cy="fulfillment-generate-button"
          >Re-Generate Design File Package</v-btn
        >
        <v-btn
          v-if="isAdmin"
          color="warning"
          @click="gotoLink('/design-packages/project/' + $route.params.id)"
          >Design File Package View</v-btn
        >
      </v-toolbar>
      <v-row dense v-if="isAdmin">
        <v-col cols="12" md="6">
          <v-toolbar dense flat class=" mb-3 text--warning">
            <v-autocomplete
              :items="fdStateList"
              item-disabled="disable"
              label="Select State"
              item-text="description"
              item-value="pk"
              v-model="selectedState"
              prepend-icon="mdi-list-status"
              return-object
              hide-details
              single-line
              clearable
              color="warning"
            >
            </v-autocomplete>
            <v-btn
              :disabled="!selectedState"
              icon
              color="warning"
              @click="updateState"
              ><v-icon>mdi-content-save</v-icon></v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-sheet
        v-for="(designFilePackage, index) in designFilePackages"
        :key="index"
        outlined
        class="ma-1 pa-1"
        elevation="6"
      >
        <FixtureDesignBlock
          v-if="index === 0"
          :isAdmin="isAdmin"
          :designFilePackage="designFilePackage"
          :selectedProject="selectedProject"
        />
      </v-sheet>
    </div>
    <BaseBottomDialog
      :dialog="bottomDialog"
      :message="confirmMessage"
      :action="confirmAction"
      @saveState="saveStateHandler"
      @saveIR="saveIRHandler"
      @saveFA="saveFAHandler"
      @donothing="stopDialog"
    />
  </v-container>
</template>
<script>
import BottomDialog from "@/mixins/BottomDialog";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FulfillmentPage",
  mixins: [BottomDialog],
  components: {
    FixtureDesignBlock: () => import("@/modules/design-packages/components/FixtureDesignBlock.vue"),
  },
  data() {
    return {
      isLoading: false,
      receptacle50: 1.27,
      receptacle75: 1.91,
      receptacle100: 2.54,
      selectedState: null,
      selectedIR: null,
      selectedFA: null,
    };
  },
  computed: {
    ...mapGetters({
      fixtureDesign: "fixturedesign/fixtureDesign",
      selectedProject: "projects/selProject",
      fdState: "fixturedesign/fdState",
      irState: "fixturedesign/irState",
      faState: "fixturedesign/faState",
      fdStateLongList: "ui/fdStateLongList",
      isAdmin: "auth/isAdmin",
    }),
    projectName() {
      return this.selectedProject.project_name;
    },
    fileName() {
      return this.fixtureDesign.dut_configs[0].testpoint_file.split("?")[0].split("/").pop();
    },
    fixturePk() {
      return this.fixtureDesign.pk;
    },
    fixtureState() {
      return this.fixtureDesign.state;
    },
    fixtureStateName() {
      const result = this.fdState.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : "";
    },
    partNumber() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.number : "";
    },
    partDescription() {
      return this.fixtureDesign.part ? this.fixtureDesign.part.description : "";
    },
    stateDescription() {
      const result = this.fdStateLongList.find(
        (element) => element.pk === this.fixtureDesign.state
      );
      return result ? result.description : null;
    },
    fdStateList() {
      return this.fdState.map((element) => ({
        pk: element.pk,
        description: element.description,
        disable: element.pk === this.fixtureState,
      }));
    },
    designFilePackages() {
      return [
        [...this.fixtureDesign.design_file_packages].sort((a, b) =>
          a.id < b.id ? 1 : -1
        )[0],
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchFixtureDesign: "fixturedesign/getFixtureDesign",
      patchFixtureDesign: "fixturedesign/patchFixtureDesign",
      genFixtureDesign: "fixturedesign/generateFixtureDesign",
      fetchProject: "projects/fetchProject",
      initFixtureDesignState: "fixturedesign/initFixtureDesignState",
      initSelectedProject: "projects/clearProject",
      fetchState: "fixturedesign/getFixtureDesignState",
      fetchDesignOptions: "fixturedesign/getDesignFileOptions",
      toggleLoading: "ui/loading",
    }),
    async onGenerate() {
      try {
        const response = await this.genFixtureDesign(this.fixtureDesign.pk);
        this.$store.commit("ui/SNACK_BAR", response);
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
    },
    downloadDesign() {
      this.$store.commit("ui/SNACK_LINK", {
        message1:
          "Downloading the Design File Package is a work in progress! Please request one from ",
        message2: " and we will email this to you.",
        link: {
          email: true,
          text: "support@fixturfab.com",
          url: "mailto:support@fixturfab.com",
        },
      });
    },
    gotoLink(link) {
      this.$router.push(link);
    },
    updateState() {
      this.confirmAction = "saveState";
      this.confirmMessage = `Are you sure you want to update the Fixture Design state from ${this.fixtureStateName} to ${this.selectedState.description}?`;
      this.bottomDialog = true;
    },
    async saveStateHandler() {
      this.stopDialog();
      await this.patchFDHandler({ state: this.selectedState.pk });
      this.selectedState = null;
    },
    async saveIRHandler() {
      this.stopDialog();
      await this.patchFDHandler({ internal_review_sub_state: this.selectedIR });
      this.selectedIR = null;
    },
    async saveFAHandler() {
      this.stopDialog();
      await this.patchFDHandler({
        fabrication_assembly_sub_state: this.selectedFA,
      });
      this.selectedFA = null;
    },
    async patchFDHandler(payload) {
      try {
        await this.patchFixtureDesign({
          pk: this.fixturePk,
          data: payload,
        });
        this.toggleLoading("Fetching Fixture Design");
        await this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        });
        this.toggleLoading();
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to process request, try later."
        );
      }
      this.stopDialog();
    },
  },
  async created() {
    this.isLoading = true;
    this.toggleLoading("Loading Fixture Design");
    try {
      await this.fetchState();
      await Promise.all([
        this.fetchFixtureDesign({
          params: { project_id: this.$route.params.id },
        }),
        this.fetchDesignOptions(),
        this.fetchProject(this.$route.params.id),
      ]);
      this.toggleLoading();
      this.isLoading = false;
    } catch (err) {
      this.toggleLoading();
      this.isLoading = false;
      this.$store.commit(
        "ui/SNACK_BAR",
        "Failed to process request (Fulfillment), try later."
      );
    }
  },
  beforeDestroy() {
    this.initSelectedProject();
  },
};
</script>
